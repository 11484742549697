import React from 'react';
import { Link } from 'gatsby';

const CallToAction = props => {
    return (
        <section className="cta">
            <h2>
                Si eres una persona <u>súper ocupada</u>, ésto te servirá.
            </h2>
            <p>El tiempo que te vas a ahorrar lo ocuparás en cosas productivas.</p>
            <Link className="button" to="/descargar">
                Quiero probar el servicio
            </Link>
        </section>
    );
};

export default CallToAction;
